/* global Component */
class orderDetailComponentB2C extends Component {

    static name() {
        return "orderDetailComponent";
    }

    static componentName() {
        return "orderDetailComponent";
    }

    getProps() {
        return  ['SOSerNr',"paymentStatus","paymentStatusCode","goBackView"];
    }

    data() {
        return {
            viewMode:true,
            backup:null,
            refreshFlag:1,
            isOrderLoading:true
        };
    }

    getWatch() {
        return {
            'SOSerNr'(to, from) {
                //console.log(this.currentOrder);
                this.$store.dispatch('orderDetail',this.SOSerNr);
            },
            'refreshFlag'(){
                //console.log(this.currentOrder);
                this.$store.dispatch('orderDetail',this.SOSerNr);
            }
        };
    }

    created() {
        return function () {
            if(this.SOSerNr)
                this.loadSalesOrder();
            else
                this.emitEvent('reset-order-view');
        };
    }

    mounted() {
        return function () {
            if(this.$route.query.action === "editOrder")
                this.viewMode = false;
        };
    }

    getComputed() {
        return {
            showItemImage: function () {
                return this.$store.getters.showItemImageInDetail;
            },
            showDiscount:function (){
                return this.$store.getters.getSetting.ShowDiscountInOrderDetail;
            },
            orderNeedLiberation:function (){
                return this.currentOrder.fields.OrderStatus === this.$store.getters.getSetting.PreAutorizationStatus;
            },
            currentOrder: function (){
                return this.$store.getters.getCurrentOrder;
            },
            currentOrderStatus: function (){
                return this.$store.getters.getSetting.OrderStatusList;
            },
            OrderStatusList: function (){
                return this.$store.getters.getOrderStatusList;
            },
            editable:function () {
                if(this.currentOrder.fields.Status || this.currentOrder.fields.Closed)
                    return false;
                if(this.isUserOnlyModifier() || this.isUserLiberator() || this.currentOrder.fields.Contact === this.$store.state.session.fields.Code) {
                    return true;
                }
                return false;
            },
            liberator:function () {
                if(!this.viewMode)
                    return false;
                return this.isUserLiberator();
            },
            copier:function (){
                if(this.isUserOnlyModifier())
                    return false;
                if(this.currentOrder.fields.Contact === this.$store.state.session.fields.Code)
                    return true;
                return this.$store.getters.getUserIsAdmin;
            }
        };
    }

    orderPay(){
        if(this.currentOrder.fields.Closed != 1)
            return this.$store.getters.getSetting.PayTermRow.filter(x => (x.fields.RequireOnlinePayment && x.fields.PayTermCode === this.currentOrder.fields.PayTerm)).length > 0;
        return false;
    }

    orderPayed(){
        return this.paymentStatus === 1 && this.paymentStatusCode === "approved" ;
    }

    isUserOnlyModifier(){
        return this.$store.state.onlyModifiedCodes.indexOf(this.currentOrder.fields.Contact) != -1 || this.$store.state.CenterOnlyModifiedCodes.indexOf(this.currentOrder.fields.CenterCost) != -1;
    }

    isUserLiberator(){
        //console.log("Liberatior Action ",this.$store.state.liberatorCodes,this.data.Contact);
        return this.$store.state.liberatorCodes.indexOf(this.currentOrder.fields.Contact) != -1 || this.$store.state.CenterLiberators.indexOf(this.currentOrder.fields.CenterCost) != -1;
    }

    goBack(){
        this.emitEvent('reset-order-view');
    }

    itemQtyIncrement(orderItem){
        orderItem.fields.Qty = parseInt(orderItem.fields.Qty) + 1;
        this.updateSubTotals();
    }

    itemQtyDecrement(orderItem){
        if(parseInt(orderItem.fields.Qty) > 0)
            orderItem.fields.Qty = parseInt(orderItem.fields.Qty) - 1;
        if(orderItem.fields.Qty === 0){
            // eslint-disable-next-line no-alert
            let canDelete = confirm("Delete Item");
            if(canDelete)
                this.deleteItemInOrder(orderItem);
            else
                orderItem.fields.Qty = 1;
        }
        this.updateSubTotals();
    }

    deleteItemInOrder(orderItem) {
        //console.log('Before',this.currentOrder.fields.Items);
        this.$store.dispatch('deleteItemCurrenOrder',orderItem);
        //console.log('After',this.currentOrder.fields.Items);
    }

    copyOrder() {
        //console.log('custom-actions: ', this.data, this.index,this.column);
        this.$store.dispatch('copyOrder',{orderId:this.currentOrder.fields.SerNr});
    }

    updateSubTotals(){
        let totalWithIVA = 0;
        let totalWithOutIVA = 0;
        for(let item of this.currentOrder.fields.Items){
            item.fields.RowNet = item.fields.Price * item.fields.Qty;
            item.fields.RowTotal = item.fields.VATPrice * item.fields.Qty;
            totalWithIVA += item.fields.RowTotal;
            totalWithOutIVA += item.fields.RowNet;
        }
        this.currentOrder.fields.SubTotal = totalWithOutIVA;
        this.currentOrder.fields.Total = totalWithIVA;
    }

    changeMode(){
        this.viewMode = !this.viewMode;
    }

    async saveOrder(){
        let result = await this.$store.dispatch('saveCurrentOrder');
        if(!result.success) {
            this.$store.commit('updateCurrentOrder', this.backup);
            this.refreshFlag = this.refreshFlag + 1;
        }
        this.changeMode();
    }

    cancelEdit(){
        this.loadSalesOrder();
        this.changeMode();
    }

    orderAction(action) {
        this.isOrderLoading = true;
        this.$store.dispatch('orderAction',{action:action,sernr:this.currentOrder.fields.SerNr,date:this.currentOrder.fields.TransDate}).then((actionResponse)=>{
            this.loadSalesOrder();
        });
    }

    getVATCode(item){
        let VATCode = this.$store.state.vatCodeMap.get(item.fields.VATCode);
        if(VATCode && VATCode.Percent)
            return VATCode.Percent;
        return 0;
    }

    loadSalesOrder(){
        //console.log('ComponetCreated')
        this.isOrderLoading = true;
        let self = this;
        this.$store.dispatch('orderDetail',this.SOSerNr).then((orderObj)=>{
            if(orderObj.order) {
                self.backup = Object.assign({}, orderObj.order);
                self.isOrderLoading = false;
            }
            else{
                this.$store.dispatch('showNotificacion',{title:"Information", message: `Order ${self.SOSerNr} not found`,type: 'warning'});
                this.$router.go(-1);
            }
        });
    }

    getUrlData(){
        return window.btoa(this.currentOrder.fields.SerNr);
    }

    getMethods() {
        return {
            getUrlData:this.getUrlData,
            orderPay:this.orderPay,
            orderPayed:this.orderPayed,
            isUserOnlyModifier:this.isUserOnlyModifier,
            isUserLiberator:this.isUserLiberator,
            goBack:this.goBack,
            itemQtyIncrement:this.itemQtyIncrement,
            itemQtyDecrement:this.itemQtyDecrement,
            deleteItemInOrder:this.deleteItemInOrder,
            copyOrder:this.copyOrder,
            updateSubTotals:this.updateSubTotals,
            changeMode:this.changeMode,
            saveOrder:this.saveOrder,
            cancelEdit:this.cancelEdit,
            orderAction:this.orderAction,
            getVATCode:this.getVATCode,
            loadSalesOrder:this.loadSalesOrder
        };
    }

    getTemplate() {
        return `<div id="container" class="order-detail" v-if="refreshFlag>0">
                <div class="container-fluid px-0 px-sm-3">
                    <div class="col-12 px-1 px-md-3">
                         <template v-if="isOrderLoading">
                              <div class="loading">
                                  <i class="fas fa-circle-notch fa-spin fa-3x"></i>
                              </div>
                          </template>
                         <template v-else>
                            <div class="row">
                              <div class="col-12 px-1 px-md-3">
                                <div v-if="currentOrder">
                                   <h5 class="title">
                                        <button class="btn secondary-btn" @click='goBack'><i class="icon fas fa-chevron-left"></i>{{tr('Back')}}</button>
                                        <span class="center mt-2 mt-sm-0 d-block d-sm-inline">{{tr('Sales Order')}} {{currentOrder.fields.SerNr}}</span>
                                   </h5>

                                   <div class="col-12 px-0 px-sm-3 mt-3 mb-4">
                                       <div class="stepwizard order-status">
                                           <div class="stepwizard-row">
                                               <div v-for="status in currentOrderStatus" class="stepwizard-step" :style="{'width': 100 / currentOrderStatus.length + '%'}">
                                                   <p>{{status.fields.StateTitle}}</p>
                                                   <button :class="{'btn disabled': true, 'active': status.fields.StateStatus === currentOrder.fields.OrderStatus}" :disabled="true">
                                                      <i :class="['fas',  (status.fields.StateIcon) ? 'fa-' + status.fields.StateIcon : 'fa-check']"></i>
                                                   </button>
                                               </div>
                                           </div>
                                       </div>
                                   </div>

                                   <div class="row order-customer-info">
                                      <div class="col-12 col-sm-6">
                                          <label>{{tr("Customer")}}:</label> <strong> {{currentOrder.fields.CustName}}</strong>
                                      </div>
                                      <div v-if="currentOrder.fields.ContactName" class="col-12 col-sm-6">
                                          <label>{{tr("Contact")}}:</label> <strong> {{currentOrder.fields.ContactName}}</strong>
                                      </div>
                                      <div class="col-12 col-sm-6">
                                          <label>{{tr("Delivery Address")}}:</label> <strong> {{currentOrder.fields.DelAddress}}</strong>
                                      </div>
                                      <div class="col-12 col-sm-6">
                                          <label>{{tr("TransDate")}}:</label> <strong> {{currentOrder.fields.TransDate}}</strong>
                                      </div>
                                      <div class="col-12 col-sm-6">
                                          <label>{{tr("Order Status")}}:</label> <strong> {{OrderStatusList[currentOrder.fields.OrderStatus]}}</strong>
                                      </div>
                                      <div class="col-12 col-sm-6">
                                            <label>{{tr("Expiration Date")}}:</label> <strong> {{currentOrder.fields.DueDate}}</strong>
                                      </div>
                                   </div>
                                   <div class="custom-actions-row">
                                        <div class="custom-actions-default">
                                            <template v-if="orderPay()">
                                                <router-link  :key="'actions-pay-'+currentOrder.fields.SerNr" :to="'/payment/'+getUrlData()" class="btn btn-custom mb-1" v-show="!orderPayed()" :title="tr('Pay Now')" >
                                                    <i class="icon far fa-credit-card"></i> {{tr('Pay Now')}}
                                                </router-link>
                                                <button class="btn mb-1" :key="'actions-reject-'+currentOrder.fields.SerNr"  v-show="!orderPayed()"  @click.stop="orderAction('invalidate')" :title="tr('Invalidate')" >
                                                    <i class="icon fa fa-minus"></i> {{tr('Reject')}}
                                                </button>
                                            </template>
                                            <button key="copy-action" :title="tr('Copy')" @click.stop="copyOrder()" v-if="copier" class="btn mb-1">
                                                <i class="icon fa fa-copy"></i> {{tr('Copy')}}
                                            </button>
                                        </div>
                                   </div>
                                   <div class="table-responsive">
                                        <table v-if="viewMode" class="table table-striped">
                                             <thead>
                                                <tr>
                                                    <th v-if="showItemImage"></th>
                                                    <th>{{tr('Code')}}</th>
                                                    <th width="35%">{{tr('Products')}}</th>
                                                    <th>{{tr('UM')}}</th>
                                                    <th class="text-center">{{tr('Qty')}}</th>
                                                    <th class="text-right">{{tr('Price')}} IVA Inc</th>
                                                    <template v-if="showDiscount">
                                                        <th class="text-right">{{tr('Discount')}} %</th>
                                                    </template>
                                                    <th>IVA</th>
                                                    <th class="text-right">{{tr('Total')}} IVA Inc</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr v-for="item of currentOrder.fields.Items">
                                                    <td v-if="showItemImage" width="5%">
                                                        <template v-if="$store.getters.getItemByCode(item.fields.ArtCode).images || !$store.getters.getItemByCode($store.getters.getItemByCode(item.fields.ArtCode).Father)">
                                                            <itemSliderComponent :item="$store.getters.getItemByCode(item.fields.ArtCode)" :container="false" :showCarusel="false"></itemSliderComponent>
                                                        </template>
                                                        <template v-else>
                                                            <itemSliderComponent :item="$store.getters.getItemByCode($store.getters.getItemByCode(item.fields.ArtCode).Father)" :container="false" :showCarusel="false"></itemSliderComponent>
                                                        </template>
                                                    </td>
                                                    <td>{{item.fields.ArtCode}}</td>
                                                    <td width="35%">{{item.fields.Name}}</td>
                                                    <td>{{item.fields.Unit}}</td>
                                                    <td class="text-center">{{item.fields.Qty}} </td>
                                                    <td class="text-right">$ {{formatFloat(item.fields.VATPrice)}}</td>
                                                    <template v-if="showDiscount">
                                                    <td class="text-right">
                                                        <template v-if="item.fields.Discount">
                                                            {{formatFloat(item.fields.Discount)}} %
                                                        </template>
                                                    </td>
                                                    </template>
                                                    <td>{{getVATCode(item)}} %</td>
                                                    <td  class="text-right">$ {{formatFloat(item.fields.RowTotal)}}</td>
                                                </tr>
                                             </tbody>
                                             <tfoot class="table-info">
                                                 <td v-if="showItemImage"></td>
                                                 <td :colspan="showDiscount ? 5 : 4" class="text-right">
                                                     <strong>SubTotal:</strong>
                                                    {{formatFloat(currentOrder.fields.SubTotal)}}
                                                 </td>
                                                 <td class="text-right">
                                                    <strong>{{tr('Taxes')}}:</strong>
                                                    {{formatFloat(currentOrder.fields.TotalTax)}}
                                                 </td>
                                                 <td class="text-right">
                                                    <strong>{{tr('IVA')}}:</strong>
                                                    {{formatFloat(currentOrder.fields.VATTotal)}}
                                                 </td>
                                                 <td class="text-right">
                                                    <strong>Total</strong>
                                                    {{formatFloat(currentOrder.fields.Total)}}
                                                 </td>
                                             </tfoot>
                                        </table>
                                        <table v-if="!viewMode" class="table table-striped">
                                             <thead>
                                                <tr>
                                                    <th v-if="showItemImage"></th>
                                                    <th>{{tr('Code')}}</th>
                                                    <th width="35%">{{tr('Products')}}</th>
                                                    <th>{{tr('UM')}}</th>
                                                    <th>{{tr('Qty')}}</th>
                                                    <th>{{tr('Price')}} IVA Inc</th>
                                                    <th>{{tr('Total')}} IVA Inc</th>
                                                    <th>{{tr('Delete')}}</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr v-for="item of currentOrder.fields.Items">
                                                    <td v-if="showItemImage" width="5%"><itemSliderComponent :item="$store.getters.getItemByCode(item.fields.ArtCode)" :container="false" :showCarusel="false"></itemSliderComponent></td>
                                                    <td>{{item.fields.ArtCode}}</td>
                                                    <td width="35%">{{item.fields.Name}}</td>
                                                    <td>{{item.fields.Unit}}</td>
                                                    <td class="row">
                                                        <span  class="col-2" @click="itemQtyIncrement(item)"><i class="fas fa-angle-up waves-effect" ></i></span>
                                                        <input class="col-8" type="text" min="1" v-model="item.fields.Qty" placeholder="0" @change="updateSubTotals">
                                                        <span  class="col-2"@click="itemQtyDecrement(item)"><i class="fas fa-angle-down waves-effect" ></i></span>
                                                    </td>
                                                    <td>$ {{formatFloat(item.fields.VATPrice)}}</td>
                                                    <td>$ {{formatFloat(item.fields.RowTotal)}}</td>
                                                    <td><span @click="deleteItemInOrder(item)" ><i class="far fa-trash-alt" ></i></span></td>
                                                </tr>
                                             </tbody>
                                             <tfoot class="table-info">
                                                 <td v-if="showItemImage"></td>
                                                 <td class="text-right"><strong>SubTotal:</strong></td>
                                                 <td>{{formatFloat(currentOrder.fields.SubTotal)}}</td>
                                                 <td class="text-right"><strong>{{tr('Taxes')}}:</strong></td>
                                                 <td>{{formatFloat(currentOrder.fields.TotalTax)}}</td>
                                                 <td class="text-right"><strong>{{tr('IVA')}}:</strong></td>
                                                 <td>{{formatFloat(currentOrder.fields.VATTotal)}}</td>
                                                 <td class="text-right"><strong>Total</strong></td>
                                                 <td>{{formatFloat(currentOrder.fields.Total)}}</td>
                                             </tfoot>
                                        </table>
                                    </div>
                                </div>
                              </div>
                            </div>
                          </template>
                    </div>
                </div>
            </div>`;
    }
}

orderDetailComponentB2C.registerComponent();
